import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a2b0520"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "small-content setup-page" }
const _hoisted_2 = { class: "medium-flex" }
const _hoisted_3 = { class: "location-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_setup_module_header = _resolveComponent("setup-module-header")!
  const _component_setup_title_input = _resolveComponent("setup-title-input")!
  const _component_help_button = _resolveComponent("help-button")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_range = _resolveComponent("ion-range")!
  const _component_map_view = _resolveComponent("map-view")!
  const _component_setup_done_button = _resolveComponent("setup-done-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_setup_module_header, {
        title: _ctx.lang.t('title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_setup_title_input, {
                label: _ctx.lang.t('titleLabel'),
                module: "map"
              }, null, 8, ["label"]),
              _createVNode(_component_ion_card, { class: "h-fit" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.lang.t("location")) + " ", 1),
                          _createVNode(_component_help_button, {
                            path: "create/location/input",
                            right: true
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_card_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.lang.t("locationQuery")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_input, {
                                    modelValue: _ctx.CreateService.info.config.map.location,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.CreateService.info.config.map.location) = $event)),
                                    maxlength: "50",
                                    placeholder: _ctx.lang.t('searchLocation'),
                                    debounce: 500
                                  }, null, 8, ["modelValue", "placeholder"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, { size: "auto" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_ion_button, {
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.aqquireUserPosition())),
                                  size: "small"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: _ctx.locate }, null, 8, ["icon"])
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_range, {
                        debounce: 500,
                        min: "1",
                        max: "19",
                        pin: "",
                        snaps: "",
                        modelValue: _ctx.CreateService.info.config.map.zoom,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.CreateService.info.config.map.zoom) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "start",
                            icon: _ctx.search
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_map_view, {
                        query: _ctx.CreateService.info.config.map.location,
                        zoom: _ctx.CreateService.info.config.map.zoom,
                        disabled: true
                      }, null, 8, ["query", "zoom"]),
                      _createVNode(_component_setup_done_button, {
                        disabled: !_ctx.validator.valid,
                        error: _ctx.lang.t(_ctx.validator.message)
                      }, null, 8, ["disabled", "error"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}