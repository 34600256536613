
import { computed, defineComponent, onBeforeMount } from "vue";

import { search, locate } from "ionicons/icons";
import { Geolocation } from "@capacitor/geolocation";

import {
  IonPage,
  IonContent,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonInput,
  IonItem,
  IonLabel,
  IonRange,
  IonIcon,
  IonCol,
  IonButton,
  IonRow,
  alertController,
} from "@ionic/vue";

import SetupModuleHeader from "./SetupModuleHeader.vue";
import SetupTitleInput from "./SetupTitleInput.vue";
import MapView from "../../modules/map/MapView.vue";
import SetupDoneButton from "./SetupDoneButton.vue";
import HelpButton from "../../generic/help/HelpButton.vue";

import LanguageService from "../../../lib/service/LanguageService";
import CreateService from "../../../lib/service/CreateService";
import { ValidationResult } from "../../../lib/form/Validation";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "SetupMap",
  components: {
    IonPage,
    IonContent,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonInput,
    IonItem,
    IonLabel,
    IonRange,
    IonIcon,
    IonCol,
    IonButton,
    IonRow,
    SetupModuleHeader,
    SetupTitleInput,
    SetupDoneButton,
    MapView,
    HelpButton,
  },
  setup() {
    const lang = LanguageService.getTranslator("module/map");
    const router = useRouter();

    const validator = computed<ValidationResult>(() => {
      CreateService.configState.map = false;
      if (CreateService.info.config.map !== undefined) {
        if (CreateService.info.config.map.title.length < 2) {
          return {
            valid: false,
            message: "titleTooShort",
          };
        }
        if (CreateService.info.config.map.location.length < 2) {
          return {
            valid: false,
            message: "noLocation",
          };
        }
        CreateService.configState.map = true;
        return { valid: true };
      }
      return { valid: false };
    });

    const aqquireUserPosition = async () => {
      try {
        const position = await Geolocation.getCurrentPosition();
        if (CreateService.info.config.map !== undefined) {
          CreateService.info.config.map.location = `${position.coords.latitude} ${position.coords.longitude}`;
        }
      } catch (ex) {
        console.error(ex);
        const alert = await alertController.create({
          message: lang.t("locationError"),
          buttons: ["OK"],
        });
        await alert.present();
      }
    };

    onBeforeMount(() => {
      if (!CreateService.checkGuard("setup")) {
        router.push("/create");
      }
      if (CreateService.info.config.map === undefined) {
        CreateService.info.config.map = {
          title: lang.t("title"),
          location: "New York",
          zoom: 10,
        };
        CreateService.configState.map = false;
      }
    });

    return {
      lang,
      CreateService,
      search,
      locate,
      validator,
      aqquireUserPosition,
    };
  },
});
